import { TimeFormat } from "./filter.pipe";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
// Routing Module
import { AppRoutes } from "./app.routing";
// Directives
import { ApiService } from "./api.service";
import { JwtModule } from "@auth0/angular-jwt";
import { SCInterceptor } from "./_interceptor/sc.interceptor";
import { CookieService } from "ngx-cookie-service";
import { HeaderComponent } from "./layout/header/header.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
// import { ToastsContainer } from "./common/toast.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FooterComponent } from "./layout/footer/footer.component";
import { LoginlayoutComponent } from "./layout/loginlayout/loginlayout.component";
import { LoginHeaderComponent } from "./layout/loginheader/loginheader.component";
import { ResetPasswordComponent } from './profile/reset-password/reset-password.component';

export function jwtgetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
    }),
    JwtModule.forRoot({
      config: {
        whitelistedDomains: [],
        blacklistedRoutes: [],
        tokenGetter: jwtgetter,
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    TimeFormat,
    // ToastsContainer,
    FooterComponent,
    LoginlayoutComponent,
    LoginHeaderComponent,
    ResetPasswordComponent,
  ],
  providers: [
    ApiService,
    CookieService,
    TimeFormat,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SCInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule, TimeFormat],
})
export class AppModule {}
