import { Component, OnInit } from "@angular/core";

@Component({
  selector: "login-header",
  templateUrl: "./loginheader.component.html",
  styleUrls: ["./loginheader.component.scss"],
})
export class LoginHeaderComponent implements OnInit {
  show: boolean = false;
  userData = [];
  constructor() {}

  ngOnInit() {}
}
