export const environment = {
  production: false,

  mainUrl: "https://app.secondconsult.com",
  apiUrl: "https://api-prod.secondconsult.com/api/",
  rladminUrl: "https://rladmin.secondconsult.com",
  adminUrl: "https://admin.secondconsult.com",
  rlUrl: "https://rl.secondconsult.com",
  baseUrl: "https://admin.secondconsult.com",
  paymentUrl: "https://api-prod.secondconsult.com/api/",
  patientUrl: "https://patient.secondconsult.com/",
  imgUrl: "https://api-prod.secondconsult.com",
  assetUrl: "https://api-prod.secondconsult.com",
  doctorUrl: "https://doctor.secondconsult.com",
};