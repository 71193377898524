import { Component, OnInit } from "@angular/core";
import { LoginService } from "../../login/login.service";
import { HeaderComponent } from "../header/header.component";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  visiblesections = [];
  showrole: boolean = false ;
  showappointment: boolean = false;
  showdoctor: boolean = false;
  showpatient: boolean = false;
  showpayout: boolean = false;
  showadmin: boolean = false;
  showdicom: boolean = false;
  showrevenue: boolean = false;
  showcoupon: boolean = false;
  showspeciality: boolean = false;
  showlanguage: boolean = false;
  showbank: boolean = false;
  showorganizations: boolean = false;
  showSecondOpinion: boolean = false;
  showProductVideo: boolean = false;
  showDoctorLedger: boolean;
  constructor(
    private loginservice: LoginService,
    public headercomponent: HeaderComponent
  ) {
    // this.loginservice.getAdmin(506).subscribe((res)=>{
    //   this.visiblesections = res.data.visible_sections;
    //   this.hideSection();
    // });
  }

  ngOnInit(): void {
    this.loginservice.getUserDetails().subscribe((response:any) => {
      let user_data = response.data;
      if(user_data.permissions_list == undefined || null){
        return;
      }else{
        user_data.permissions_list.map((element) => {
          switch (element) {
            case "admin-view":
              this.showrole = true;
              break;
            case "appointment-view":
              this.showappointment = true;
              break;
            case "doctor-view":
              this.showdoctor = true;
              break;
            case "patient-view":
              this.showpatient = true;
              break;
            case "payout-view":
              this.showpayout = true;
              break;
            case "admin-view":
              this.showadmin = true;
              break;
            case "dicom-view":
              this.showdicom = true;
              break;
            case "revenue-view":
              this.showrevenue = true;
              break;
            case "coupon-view":
              this.showcoupon = true;
              break;
            case "speciality-view":
              this.showspeciality = true;
              break;
            case "language-view":
              this.showlanguage = true;
              break;
            case "bank-view":
              this.showbank = true;
              break;
            case "organizations-view":
              this.showorganizations = true;
              break;
            case "secondopinion-view":
              this.showSecondOpinion = true;
              break;
            case "productvideos-view" :
              this.showProductVideo = true;
              break;
            case "doctorledger-view":
              this.showDoctorLedger = true;
              break;
            default:
              return;
          }
        });
      }
    })
  }
  hideSection(){
    this.visiblesections.forEach((element)=>{
       switch (element) {
          case "appointment-view":
            this.showappointment = true;
            break;
          case "doctor-view":
            this.showdoctor = true;
            break;
          case "patient-view":
            this.showpatient = true;
            break;
          case "payout-view":
            this.showpayout = true;
            break;
          case "admin-view":
            this.showadmin = true;
            break;
          case "dicom-view":
            this.showdicom = true;
            break;
          case "revenue-view":
            this.showrevenue = true;
            break;
          case "coupon-view":
            this.showcoupon = true;
            break;
          case "speciality-view":
            this.showspeciality = true;
            break;
          case "language-view":
            this.showlanguage = true;
            break;
          case "bank-view":
            this.showbank = true;
            break;
          case "organizations-view":
            this.showorganizations = true;
            break;
          default:
            return;
       }
    });
  }
  logout() {
    this.loginservice.logout();
  }

}
