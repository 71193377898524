import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

// user.ts
export interface LoginResponse {
  access_token: string;
  data: any;
  name: string;
  status: string;
  message: string;
}
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class LoginService {
  locastrg: any;
  constructor(
    public jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    this.locastrg = localStorage;
  }

  getToken() {
    return this.cookieService.get("admin_access_token");
  }
  getUserData() {
    const token = this.getToken();
    if (token) {
      return this.jwtHelper.decodeToken(token);
    }
    this.logout();
  }

  isExpired() {
    const token = this.getToken();
    return this.jwtHelper.isTokenExpired(token);
  }

  getUserRole() {
    const userData = this.getUserData();
    return userData.role;
  }

  login(user: any): Observable<any> {
    let param = new HttpParams();
    param = param.append("email", user.email);
    param = param.append("password", user.pass);
    param = param.append("remember", user.remember);
    return this.http.post(API_URL + "login-admin", param);
  }

  // After login save token and other values(if any) in localStorage
  setUser(response: LoginResponse, registraion = false) {
    var myDate = new Date();
    const url = environment.adminUrl.replace(/(^\w+:|^)\/\//, "");
    myDate.setMonth(myDate.getMonth() + 12);
    localStorage.setItem("name", response.name || "");
    localStorage.setItem("access_token", response.access_token);
    document.cookie =
      "admin_access_token=" +
      response.access_token +
      ";expires=" +
      myDate +
      `;domain=.${url};path=/`;
    if (!registraion)
      window.location.href = environment.adminUrl + "/#/dashboard";
  }

  // Checking if token is set
  isLoggedIn() {
    let token = this.cookieService.get("admin_access_token");
    if (token && !this.isExpired()) {
      return true;
    } else {
      return false;
    }
    //return true if user logged in
  }

  // After clearing localStorage redirect to login screen
  logout() {
    const url = environment.adminUrl.replace(/(^\w+:|^)\/\//, "");
    this.cookieService.delete("admin_access_token", "/", "." + url);
    let loginurl = environment.adminUrl + "/#/";
    window.location.href = loginurl;
  }
  getAdmin(id): Observable<any> {
    let apiURL = API_URL + "admins/" + id;
    return this.http.get(apiURL);
  }

  getUserDetails(){
    let apiUrl = `${API_URL}get-user-details`;
    return this.http.get(apiUrl);
  }
}
