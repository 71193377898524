import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor(private http: HttpClient) {}

  PatientDetails(): Observable<any> {
    return;
    // let apiURL = API_URL + "get-admin-details";
    // return this.http.get(apiURL);
  }

  getUserData(){
    let apiUrl = `${API_URL}get-user-details`;
    return this.http.get(apiUrl);
  }
}
