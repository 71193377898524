<footer>
  <div class="footer pt-lg-4 footer-dark fbg">
    <div class="container pt-4">
      <div class="row">
        <div class="mb-5 mb-lg-0">
          <a href="{{ baseUrl }}">
            <img
              alt="Second Consult"
              src="../../../assets/images/footer-logo.png"
              id="footer-logo"
            />
          </a>
          <div class="member mt-4"></div>
          <div class="member mt-4">
            <h5>Want to join our provider network</h5>
            <div><a href="#" class="anm">Apply Here</a></div>
          </div>
        </div>
        <div class="mb-5 mb-lg-0">
          <h5 class="heading mb-3">Services</h5>
          <ul class="list-unstyled">
            <li>
              <a href="{{ baseUrl }}/specialities">Specialities</a>
            </li>
            <li>
              <a href="{{ baseUrl }}/consultation">Consultation</a>
            </li>
            <li>
              <a href="{{ baseUrl }}/second-opinion">Second Opinion</a>
            </li>
            <li>
              <a href="{{ baseUrl }}/membership">Membership</a>
            </li>
          </ul>
        </div>

        <div class="mb-5 mb-lg-0 footerinfo">
          <h5 class="heading mb-3">Company</h5>
          <ul class="list-unstyled">
            <li><a href="{{ baseUrl }}/about-us">About Us</a></li>
            <li>
              <a href="{{ baseUrl }}/success-stories">Success Stories</a>
            </li>
            <!--<li><a href="{{ baseUrl }}/press">Press</a></li>-->
            <li><a href="{{ baseUrl }}/blog">Blog</a></li>
            <!--<li><a href="{{ baseUrl }}/help">Help</a></li>
                            <li><a href="{{ baseUrl }}/faq">FAQ's</a></li>-->
            <li>
              <a href="{{ baseUrl }}/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
        <div class="mb-5 mb-lg-0 footerinfo">
          <h5 class="heading mb-3">Contact</h5>
          <div class="partnership fhead mb-4">
            <div>
              <h5>Sales and partnerships</h5>
              <a href="mailto:partnerships@secondconsult.com"
                >partnerships@secondconsult.com</a
              >
            </div>
          </div>
          <div class="careers fhead mb-4">
            <div>
              <h5>Careers</h5>
              <a href="mailto:careers@secondconsult.com"
                >careers@secondconsult.com</a
              >
            </div>
            <h5>
              <a href="{{ baseUrl }}/careers" class="flink">We're hiring!</a>
            </h5>
          </div>
          <div class="support fhead mb-4">
            <div>
              <h5>Support</h5>
              <p>
                <a href="mailto:support@secondconsult.com"
                  >support@secondconsult.com</a
                >
              </p>
            </div>
          </div>
          <div class="social fhead mb-3">
            <a
              href="https://www.linkedin.com/company/second-consult-healthcare/?viewAsMember=true"
              target="_blank">
              <svg
                height="24pt"
                viewBox="0 0 512 512"
                width="24pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm-74.390625 387h-62.347656v-187.574219h62.347656zm-31.171875-213.1875h-.40625c-20.921875 0-34.453125-14.402344-34.453125-32.402344 0-18.40625 13.945313-32.410156 35.273437-32.410156 21.328126 0 34.453126 14.003906 34.859376 32.410156 0 18-13.53125 32.402344-35.273438 32.402344zm255.984375 213.1875h-62.339844v-100.347656c0-25.21875-9.027343-42.417969-31.585937-42.417969-17.222656 0-27.480469 11.601563-31.988282 22.800781-1.648437 4.007813-2.050781 9.609375-2.050781 15.214844v104.75h-62.34375s.816407-169.976562 0-187.574219h62.34375v26.558594c8.285157-12.78125 23.109375-30.960937 56.1875-30.960937 41.019531 0 71.777344 26.808593 71.777344 84.421874zm0 0"
                ></path>
              </svg>
            </a>
            <a href="https://www.facebook.com/SecondConsultHealthcare"  target="_blank">
              <svg
                id="Capa_1"
                enable-background="new 0 0 512 512"
                height="24pt"
                viewBox="0 0 512 512"
                width="24pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m512 256c0-141.4-114.6-256-256-256s-256 114.6-256 256 114.6 256 256 256c1.5 0 3 0 4.5-.1v-199.2h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5c107-30.7 185.3-129.2 185.3-246.1z"
                ></path>
              </svg>
            </a>
            <a href="https://www.instagram.com/secondconsulthealthcare/"  target="_blank">
              <svg
                height="24pt"
                viewBox="0 0 512 512"
                width="24pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0"
                ></path>
                <path
                  d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0"
                ></path>
                <path
                  d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm146.113281 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <hr class="divider divider-fade divider-dark my-4" />
      <div class="row align-items-center justify-content-md-between pb-4">
        <div class="col-md-6">
          <div class="copyright anr text-center text-md-left">
            © {{ currentYear }} Secondoconult LLP.
          </div>
        </div>
        <div class="col-md-6">
          <ul
            class="
              nav
              justify-content-center justify-content-md-end
              mt-3 mt-md-0
            "
          >
            <li class="nav-item">
              <a class="nav-link" href="{{ baseUrl }}/terms">Terms</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="{{ baseUrl }}/privacy-policy"
                >Privacy</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="{{ baseUrl }}/hipaa">HIPPAA</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="{{ baseUrl }}/data-privacy"
                >Data Privacy</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- <footer>
  <div class="container">
    <div class="row padbot30">
      <div class="col col-12 col-lg-4 col-md-4 padtop35 padbot20">
        <figure>
          <a routerLink=""
            ><img
              src="/assets/images/footer-logo.png"
              class="img-fluid width68"
          /></a>
        </figure>
      </div>
      <div class="col col-6 col-lg-3 col-md-3 padtop35 padbot20">
        <ul class="footer-link">
          <li class="footer-nav"><a routerLink="/about-us">About us</a></li>
          <li class="footer-nav"><a routerLink="/contact-us">Contact us</a></li>
          <li class="footer-nav"><a routerLink="/faq">FAQ's</a></li>
        </ul>
      </div>
      <div class="col col-6 col-lg-3 col-md-3 padtop35 padbot20">
        <ul class="footer-link">
          <li class="footer-nav">
            <a routerLink="/privacy-policy">Privacy Policy</a>
          </li>
          <li class="footer-nav">
            <a routerLink="/terms-condition">Terms of Use</a>
          </li>
          <li class="footer-nav"><a routerLink="/blog">Blog</a></li>
        </ul>
      </div>
      <div class="col col-12 col-lg-2 col-md-2 padtop35 padbot20">
        <h4 class="social-titile">Follow Us</h4>
        <ul class="social footer-link">
          <li class="social-icons">
            <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
          </li>
          <li class="social-icons">
            <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
          </li>
          <li class="social-icons">
            <a href="#"
              ><i class="fa fa-linkedin-square" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footerrights text-center pad10s">
      <p class="footertext">&copy; 2019 SECONDCONSULT.ALL RIGHTS RESERVED.</p>
    </div>
  </div>
</footer> -->
