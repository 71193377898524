import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { LoginService } from "../login/login.service";
import { Route } from "@angular/compiler/src/core";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private loginService: LoginService) {}

  canActivate() {
    if (!this.loginService.isLoggedIn()) {
      window.location.href = environment.adminUrl;
      return false;
    } else {
      return true;
    }
  }
}
