<nav
  class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
  id="sidebar"
>
  <div class="container-fluid">
    <!-- Toggler -->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#sidebarCollapse"
      aria-controls="sidebarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleCollapse()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Brand -->
    <!-- Brand -->
    <div class="profilepic">
      <a
        class="avatar avatar-sm avatar-online dropdown-toggle cursor-pointer"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-template #notShowImage>
          <img
            class="avatar-img rounded-circle"
            src="assets/images/avatar-1.jpg"
            alt="..."
          />
        </ng-template>

        <img
          class="avatar-img rounded-circle"
          src="assets/images/default_profilepic.png"
          id="make_pic"
        />
      </a>
      <div class="patinfo">
        <div class="pid">{{data?.first_name}} {{data?.last_name}}</div>
      </div>
      <!-- <a class="update" routerLink="/reset-password">View Profile</a> -->
      <!-- <h3>Welcome</h3> -->
    </div>

    <!-- User (xs) -->
    <div class="navbar-user d-md-none">
      <!-- Dropdown -->
      <div class="dropdown">
        <!-- Toggle -->
        <a
          href="#"
          id="sidebarIcon"
          class="dropdown-toggle"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="avatar avatar-sm avatar-online">
            <!-- <img
              src="/assets/images/avatar-1.jpg"
              class="avatar-img rounded-circle"
              alt="..."
            /> -->
          </div>
        </a>

        <!-- Menu -->
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="sidebarIcon"
        >
          <a routerLink="/patient-profile" class="dropdown-item">Profile</a>
          <hr class="dropdown-divider" />
          <a (click)="logout()" class="dropdown-item cursor-pointer">Logout</a>
        </div>
      </div>
    </div>

    <!-- Collapse -->
    <div
      class="collapse navbar-collapse"
      id="sidebarCollapse"
      [ngClass]="{ show: show }"
    >
      <!-- <form class="mt-4 mb-3 d-md-none">
        <div class="input-group input-group-rounded input-group-merge">
          <input
            type="search"
            class="form-control form-control-rounded form-control-prepended"
            placeholder="Search"
            aria-label="Search"
          />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <span class="fe fe-search"></span>
            </div>
          </div>
        </div>
      </form> -->

      <app-sidebar></app-sidebar>
    </div>
    <!-- / .navbar-collapse -->
  </div>
</nav>

<div class="main-content">
  <nav
    class="navbar navbar-expand-md navbar-light d-none d-md-flex"
    id="topbar"
  >
    <div class="container-fluid">
      <div class="form-inline mr-4 d-none d-md-flex">
        <a _ngcontent-rvi-c105="" class="navbar-brand cursor-pointer">
          <img src="../../../assets/images/sc_logo1.png" alt="..." class=""
        /></a>
      </div>
      <form class="form-inline mr-4 d-none d-md-flex">
        <div
          class="input-group input-group-flush input-group-merge"
          data-list='{"valueNames": ["name"]}'
        >
          <!-- <input
            type="search"
            class="form-control form-control-prepended dropdown-toggle list-search"
            data-toggle="dropdown"
            placeholder="Search"
            aria-label="Search"
          /> -->
          <div class="input-group-prepend">
            <div class="input-group-text">
              <!-- <i class="fe fe-search"></i> -->
            </div>
          </div>
        </div>
      </form>

      <div class="navbar-user">
        <div class="dropdown mr-4 d-none d-md-flex">
          <!-- <a
            href="#"
            class="navbar-user-link"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="icon active">
              <i class="fe fe-bell"></i>
            </span>
          </a> -->

          <!-- Menu -->
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-card">
            <div class="card-header">
              <!-- Title -->
              <h5 class="card-header-title">Notifications</h5>

              <!-- Link -->
              <a href="doctor_notifications.html" class="small"> View all </a>
            </div>
            <!-- / .card-header -->
            <div class="card-body">
              <!-- List group -->
              <div
                class="list-group list-group-flush list-group-activity my-n3"
              >
                <a class="list-group-item text-reset" href="#!">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <!-- <img
                          src="/assets/images/avatar-1.jpg"
                          alt="..."
                          class="avatar-img rounded-circle"
                        /> -->
                      </div>
                    </div>
                    <div class="col ml-n2">
                      <!-- Content -->
                      <div class="small">
                        <strong>Dianna Smiley</strong> shared your post with Ab
                        Hadley, Adolfo Hess, and 3 others.
                      </div>

                      <!-- Time -->
                      <small class="text-muted"> 2m ago </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </a>
                <a class="list-group-item text-reset" href="#!">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <img
                          src="/assets/images/avatar-2.jpg"
                          alt="..."
                          class="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col ml-n2">
                      <!-- Content -->
                      <div class="small">
                        <strong>Ab Hadley</strong> reacted to your post with a
                        😍
                      </div>

                      <!-- Time -->
                      <small class="text-muted"> 2m ago </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </a>
                <a class="list-group-item text-reset" href="#">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <img
                          src="/assets/images/avatar-3.jpg"
                          alt="..."
                          class="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col ml-n2">
                      <!-- Content -->
                      <div class="small">
                        <strong>Adolfo Hess</strong> commented
                        <blockquote class="mb-0">
                          “I don’t think this really makes sense to do without
                          approval from Johnathan since he’s the one...”
                        </blockquote>
                      </div>

                      <!-- Time -->
                      <small class="text-muted"> 2m ago </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </a>
                <a class="list-group-item text-reset" href="#">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <img
                          src="/assets/images/avatar-4.jpg"
                          alt="..."
                          class="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col ml-n2">
                      <!-- Content -->
                      <div class="small">
                        <strong>Daniela Dewitt</strong> subscribed to you.
                      </div>

                      <!-- Time -->
                      <small class="text-muted"> 2m ago </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </a>
                <a class="list-group-item text-reset" href="#">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <img
                          src="/assets/images/avatar-5.jpg"
                          alt="..."
                          class="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col ml-n2">
                      <!-- Content -->
                      <div class="small">
                        <strong>Miyah Myles</strong> shared your post with Ryu
                        Duke, Glen Rouse, and 3 others.
                      </div>

                      <!-- Time -->
                      <small class="text-muted"> 2m ago </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </a>
                <a class="list-group-item text-reset cursor-pointer">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <img
                          src="/assets/images/avatar-6.jpg"
                          alt="..."
                          class="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col ml-n2">
                      <!-- Content -->
                      <div class="small">
                        <strong>Ryu Duke</strong> reacted to your post with a 😍
                      </div>

                      <!-- Time -->
                      <small class="text-muted"> 2m ago </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </a>
                <a class="list-group-item text-reset" href="#">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <img
                          src="/assets/images/avatar-7.jpg"
                          alt="..."
                          class="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col ml-n2">
                      <!-- Content -->
                      <div class="small">
                        <strong>Glen Rouse</strong> commented
                        <blockquote class="mb-0">
                          “I don’t think this really makes sense to do without
                          approval from Johnathan since he’s the one...”
                        </blockquote>
                      </div>

                      <!-- Time -->
                      <small class="text-muted"> 2m ago </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </a>
                <a class="list-group-item text-reset" href="#">
                  <div class="row">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <div class="avatar avatar-sm">
                        <img
                          src="/assets/images/avatar-8.jpg"
                          alt="..."
                          class="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="col ml-n2">
                      <!-- Content -->
                      <div class="small">
                        <strong>Grace Gross</strong> subscribed to you.
                      </div>

                      <!-- Time -->
                      <small class="text-muted"> 2m ago </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </a>
              </div>
            </div>
          </div>
          <!-- / .dropdown-menu -->
        </div>

        <!-- Dropdown -->

        <!--<div class="dropdown">
             <a
            class="avatar avatar-sm avatar-online dropdown-toggle cursor-pointer"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <ng-template #notShowImage>
              <img
                class="avatar-img rounded-circle"
                src="assets/images/avatar-1.jpg"
                alt="..."
              />
            </ng-template>

            <img
              class="avatar-img rounded-circle"
              src="assets/images/default_profilepic.png"   
              id="make_pic"
            />
          </a> -->

        <!-- Menu -->
        <!-- <div class="dropdown-menu dropdown-menu-right">
            <a routerLink="/patient-profile" class="dropdown-item">Profile</a>
            <hr class="dropdown-divider" />
            <a (click)="logout()" class="dropdown-item cursor-pointer"
              >Logout</a
            >
          </div>
        </div>-->
      </div>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
