import { HeaderService } from "./header.service";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { LoginService } from "../../login/login.service";
// const IMGURL = environment.imgUrl;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  show: boolean = false;
  profile_pic: any;
  data: any;

  constructor(
    private loginservice: LoginService,
    private headerService: HeaderService
  ) {}

  ngOnInit(): void {
    // this.headerService.PatientDetails().subscribe(
    //   (jsonData) => {
    //     let data;
    //     data = jsonData.data;
    //     this.profile_pic = data.profile_pic ? data.profile_pic : null;
    //   },
    //   (err) => {}
    // );

    this.headerService.getUserData().subscribe((response:any) => {
      this.data = response.data;
    })
  }
  toggleCollapse() {
    this.show = !this.show;
  }
  logout() {
    this.loginservice.logout();
  }
}
