import { environment } from 'src/environments/environment';
import { Component, OnInit } from "@angular/core";
import { FooterService } from "./footer.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  mainUrl: any;
  baseUrl: any;
  specialtyList: any;
  currentYear;

  constructor(private api: FooterService) {}

  ngOnInit() {
    this.mainUrl = environment.mainUrl;
    this.baseUrl = environment.baseUrl;
    this.currentYear = new Date().getFullYear();
  }
}
