<section class="main-bg loginsection" *ngIf="is_validate">
  <section class="banner-sect">
    <div class="bg-slide3"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-lg-4 col col-12">
          <div class="login-bg-circle padtop30">
            <h2 class="font20 padbot15 font-weight600">Reset password</h2>
            <div class="login-box">
              <form
                id="reset"
                [formGroup]="registerForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="alert alert-danger" *ngIf="errMsg != ''">
                  {{ errMsg }}
                </div>
                <div class="row patient-form padtop10">
                  <div class="col col-12 col-md-12 col-lg-12">
                    <input type="hidden" name="userId" value="{{ userId }}" />
                    <div class="form-group">
                      <label for="name"><strong>Old Password</strong></label>
                      <input
                        type="password"
                        placeholder="Enter your old password"
                        formControlName="old_password"
                        class="form-control margintop"
                        [(ngModel)]="reset.old_password"
                        [ngClass]="{
                          'is-invalid': submitted && f.old_password.errors
                        }"
                      />

                      <div
                        *ngIf="submitted && f.old_password.errors"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.old_password.errors?.required"
                          class="alert alert-danger"
                        >
                          Old password is required
                        </div>
                        <!-- <div
                          *ngIf="f.old_password.errors?.pattern"
                          class="alert alert-danger"
                        >
                          6-15 characters, At least one upper case character
                          (A-Z), At least one number (0-9).
                        </div> -->
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="name"><strong>New Password</strong></label>
                      <input
                        type="password"
                        placeholder="Enter your new password"
                        formControlName="password"
                        class="form-control margintop"
                        [(ngModel)]="reset.password"
                        [ngClass]="{
                          'is-invalid': submitted && f.password.errors
                        }"
                      />

                      <div
                        *ngIf="submitted && f.password.errors"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.password.errors?.required"
                          class="alert alert-danger"
                        >
                          New password is required
                        </div>
                        <div
                          *ngIf="f.password.errors?.pattern"
                          class="alert alert-danger"
                        >
                          6-15 characters, At least one upper case character
                          (A-Z), At least one number (0-9).
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row patient-form">
                  <div class="col col-12 col-md-12 col-lg-12">
                    <div class="">
                      <label for="name"
                        ><strong>Re-enter Password</strong></label
                      >
                      <input
                        type="password"
                        placeholder="Re-enter your password"
                        formControlName="cpassword"
                        class="form-control margintop"
                        [(ngModel)]="reset.cpassword"
                        [ngClass]="{
                          'is-invalid': submitted && f.cpassword.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f.cpassword.errors"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.cpassword.errors.required"
                          class="alert alert-danger"
                        >
                          Confirm password is required
                        </div>
                        <div
                          *ngIf="f.cpassword.errors?.pattern"
                          class="alert alert-danger"
                        >
                          6-15 characters, At least one upper case character
                          (A-Z), At least one number (0-9).
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row patient-form text-center pb0">
                  <div class="col col-12 col-md-12 col-lg-12">
                    <button
                      type="submit"
                      class="login-btn padtop20"
                      [disabled]="loading"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-lg-8 col col-12">
          <div class="">
            <img src="./assets/images/mainbg10.png" class="login-bg-img" />
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
