import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { Routes } from "@angular/router";
import { AuthGuard } from "./_guards/auth.guard";
import { HeaderComponent } from "../app/layout/header/header.component";
import { LoginlayoutComponent } from "../app/layout/loginlayout/loginlayout.component";
import { NotFoundComponent } from "./not-found/not-found.component";

export const AppRoutes: Routes = [
  {
    path: "",
    component: LoginlayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./login/login.module").then((module) => module.LoginModule),
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("./reset-password/reset-password.module").then(
            (module) => module.ResetPasswordModule
          ),
      },
    ],
  },
  {
    path: "",
    component: HeaderComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./dashboard/dashboard.module").then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: "patients",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./patients/patients.module").then(
            (module) => module.PatientsModule
          ),
      },
      {
        path: "patient-details",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./patient-details/patient-details.module").then(
            (module) => module.PatientDetailsModule
          ),
      },
      {
        path: "doctors",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./doctors/doctors.module").then(
            (module) => module.DoctorModule
          ),
      },
      {
        path: "category",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./category/category.module").then(
            (module) => module.CategoryModule
          ),
      },
      {
        path: "category/create-category",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./category/add/add.module").then(
            (module) => module.AddModule
          ),
      },
      {
        path: "category/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./category/edit/edit.module").then(
            (module) => module.EditModule
          ),
      },
      {
        path: "banner",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./banner/banner.module").then(
            (module) => module.BannerModule
          ),
      },

      {
        path: "banner/create-banner",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./banner/add/add.module").then(
            (module) => module.AddModule
          ),
      },
      {
        path: "banner/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./banner/edit/edit.module").then(
            (module) => module.EditModule
          ),
      },
      {
        path: "lab-appointment",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./lab-appointment/lab-appointment.module").then(
            (module) => module.LabAppointmentModule
          ),
      },
      {
        path: "lab-appointment/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./lab-appointment/edit/edit.module").then(
            (module) => module.EditModule
          ),
      },
      {
        path: "lab-appointment/:value/view",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./lab-appointment/view/view.module").then(
            (module) => module.ViewModule
          ),
      },
      {
        path: "tests",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./tests/tests.module").then(
            (module) => module.TestsModule
          ),
      },
      {
        path: "tests/create-test",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./tests/add/add.module").then(
            (module) => module.AddModule
          ),
      },
      {
        path: "tests/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./tests/edit/edit.module").then(
            (module) => module.EditModule
          ),
      },
      {
        path: "test-price",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./test-price/test-price.module").then(
            (module) => module.TestPriceModule
          ),
      },
      {
        path: "test-price/create-test-price",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./test-price/add/add.module").then(
            (module) => module.AddModule
          ),
      },

      {
        path: "test-price/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./test-price/edit/edit.module").then(
            (module) => module.EditModule
          ),
      },
      {
        path: "labs",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./labs/labs.module").then(
            (module) => module.LabsModule
          ),
      },
      {
        path: "labs/create-labs",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./labs/add/add.module").then(
            (module) => module.AddModule
          ),
      },

      {
        path: "labs/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./labs/edit/edit.module").then(
            (module) => module.EditModule
          ),
      },
      {
        path: "coupons",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./coupons/coupons.module").then(
            (module) => module.CouponsModule
          ),
      },
      {
        path: "coupons/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./coupons/edit/edit.module").then(
            (module) => module.EditModule
          ),
      },
      {
        path: "doctor-details",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./doctor-details/doctor-details.module").then(
            (module) => module.DoctorDetailsModule
          ),
      },
      {
        path: "create-doctor",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./doctor-profile/doctor-profile.module").then(
            (module) => module.DoctorProfileModule
          ),
      },
      {
        path: "update-doctor/:value",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./update-doctor-profile/update-doctor-profile.module").then(
            (module) => module.UpdateDoctorProfileModule
          ),
      },
      {
        path: "second-opinion",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./second-opinion/second-opinion.module").then(
            (module) => module.SecondOpinionModule
          ),
      },
      {
        path: "second-opinion-details",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./second-opinion-details/second-opinion-details.module").then(
            (module) => module.SecondopinionDetailsModule
          ),
      },
      {
        path: "appointments",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./appointments/appointments.module").then(
            (module) => module.AppointmentsModule
          ),
      },
      {
        path: "appointment-details",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./appointment-details/appointment-details.module").then(
            (module) => module.AppointmentDetailsModule
          ),
      },
      {
        path: "revenue",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./revenue/revenue.module").then(
            (module) => module.RevenueModule
          ),
      },
      {
        path: "radiology-centers",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./radiology-centers/radiology-centers.module").then(
            (module) => module.RadiologyCentersModule
          ),
      },
      {
        path: "create-radiology-center",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "./radiology-center-registartion/radiology-center-registartion.module"
          ).then((module) => module.RadiologyCenterRegistartionModule),
      },
      {
        path: "specialities",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./specialities/specialities.module").then(
            (module) => module.SpecialitiesModule
          ),
      },
      {
        path: "languages",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./languages/languages.module").then(
            (module) => module.LanguagesModule
          ),
      },
      {
        path: "banks",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./bank-list/bank-list.module").then(
            (module) => module.BankListModule
          ),
      },
      {
        path: "payouts",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./payouts/payouts.module").then(
            (module) => module.PayoutsModule
          ),
      },
      {
        path: "organizations",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./organizations/organizations.module").then(
            (module) => module.OrganizationsModule
          ),
      },
      {
        path: "product-videos",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./product-videos/product-videos.module").then(
            (module) => module.ProductVideosModule
          ),
      },
      {
        path: "products",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./products/products.module").then(
            (module) => module.ProductsModule
          ),
      },
      {
        path: "products/create-product",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./products/add/add.module").then(
            (module) => module.AddModule
          ),
      },
      {
        path: "products/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./products/edit/edit.module").then(
            (module) => module.EditModule
          ),
      },
      {
        path: "order-management",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./order-management/order-management.module").then(
            (module) => module.OrderManagementModule
          ),
      },
      {
        path: "order-management/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./order-management/edit/edit.module").then(
            (module) => module.EditModule
          ),
      },
      {
        path: "order-management/:value/view",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./order-management/view/view.module").then(
            (module) => module.ViewModule
          ),
      },
      {
        path: "health-data",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./health-data/health-data.module").then(
            (module) => module.HealthDataModule
          ),
      },
      {
        path: "dicom-files",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./dicom-files/dicom-files.module").then(
            (module) => module.DicomFilesModule
          ),
      },
      {
        path: "doctors-payout",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./doctors-payout/doctors-payout.module").then(
            (module) => module.DoctorPayoutModule
          ),
      },
       {
        path: "admins/:value/edit",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./update-admin/update-admin.module").then(
            (module) => module.UpdateAdminModule
          ),
      },
       {
        path: "admins",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./admins/admins.module").then(
            (module) => module.AdminsModule
          ),
      },
      {
        path: "admins/create",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./create-admin/create-admin.module").then(
            (module) => module.CreateAdminModule
          ),
      },
      {
        path: "dashboard-new",
        loadChildren: () =>
          import("./dashboard-new/dashboard-new.module").then(
            (module) => module.DashboardNewModule
          ),
      },
      {
        path: "revenue-new",
        loadChildren: () =>
          import("./revenue-new/revenue-new.module").then(
            (module) => module.RevenueNewModule
          ),
      },
      {
        path: "activity-logs",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./activity-logs/activity-logs.module").then(
            (module) => module.ActivityLogsModule
          ),
      },
    ],
  },
  {
    path:"reset-password",
    component:ResetPasswordComponent
  },

  {
    path: "not-found",
    component: NotFoundComponent,
  },
];
