<header id="navbar">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container p-topbottom5">
      <a class="navbar-brand" routerLink="/"
        ><img
          alt="Image placeholder"
          src="../../../assets/images/sc_logo.png"
          id="navbar-logo"
      /></a>
    </div>
  </nav>
</header>
