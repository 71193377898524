<!-- Navigation -->
<ul class="navbar-nav" (click)="headercomponent.toggleCollapse()">
  <li class="nav-item">
    <a class="nav-link" routerLink="/dashboard" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-tachometer"></i> Dashboard
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/activity-logs" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-history"></i> Activity Logs
    </a>
  </li>
  <li class="nav-item" *ngIf="showrole">
    <a class="nav-link" routerLink="/admins" aria-controls="tab5" role="tab" data-toggle="tab" routerLinkActive="active"
      routerLinkActiveOptions="{exact:true}">
      <i class="fe fe-user"></i> Admins
    </a>
  </li>
  <li class="nav-item" *ngIf="showappointment">
    <a class="nav-link" routerLink="/appointments" aria-controls="tab2" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fe fe-calendar"></i>Appointments
    </a>
  </li>
  <li class="nav-item" *ngIf="showSecondOpinion">
    <a class="nav-link" routerLink="/second-opinion" aria-controls="tab3" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-handshake-o" style="padding-right: 13px"></i> Second
      Opinion
    </a>
  </li>
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/doctors" aria-controls="tab4" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-user-md"></i> Doctors
    </a>
  </li>
  <li class="nav-item" *ngIf="showDoctorLedger">
    <a class="nav-link" routerLink="/doctors-payout" aria-controls="tab4" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-user-md"></i> Doctor Ledgers
    </a>
  </li>
  <li class="nav-item" *ngIf="showpatient">
    <a class="nav-link" routerLink="/patients" aria-controls="tab5" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fe fe-user"></i> Patients
    </a>
  </li>
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/products" aria-controls="tab4" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-shopping-cart"></i> Products
    </a>
  </li>
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/order-management" aria-controls="tab4" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-truck"></i> Order Management
    </a>
  </li>
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/health-data" aria-controls="tab4" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-thermometer-three-quarters"></i> Health-Data
    </a>
  </li>
  <!-- <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/radiology-centers"
      aria-controls="tab5"
      role="tab"
      data-toggle="tab"
      routerLinkActive="active"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fa fa-user-md"></i> Radiology Centers
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/patient-reports"
      aria-controls="tab5"
      role="tab"
      data-toggle="tab"
      routerLinkActive="active"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fa fa-user-md"></i> Radiologist
    </a>
  </li> -->
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/category" aria-controls="tab4" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-thermometer-three-quarters"></i> Category
    </a>
  </li>
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/lab-appointment" aria-controls="tab4" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-truck"></i> Lab Appointment
    </a>
  </li>
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/banner" aria-controls="tab4" role="tab" data-toggle="tab" routerLinkActive="active"
      routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-truck"></i> Banner
    </a>
  </li>
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/labs" aria-controls="tab4" role="tab" data-toggle="tab" routerLinkActive="active"
      routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-flask"></i> Labs
    </a>
  </li>
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/test-price" aria-controls="tab4" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-thermometer-three-quarters"></i> Test Price
    </a>
  </li>
  <li class="nav-item" *ngIf="showdoctor">
    <a class="nav-link" routerLink="/tests" aria-controls="tab4" role="tab" data-toggle="tab" routerLinkActive="active"
      routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-thermometer-three-quarters"></i> Tests
    </a>
  </li>
  <li class="nav-item"*ngIf="showrevenue">
    <a class="nav-link" routerLink="/revenue" aria-controls="tab5" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-inr"></i> Revenue
    </a>
  </li>
  <li class="nav-item" *ngIf="showpayout">
    <a class="nav-link" routerLink="/payouts" aria-controls="tab5" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-money"></i>
      Payouts
    </a>
  </li>
  <li class="nav-item"*ngIf="showdicom">
    <a class="nav-link" routerLink="/dicom-files" aria-controls="tab5" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fe fe-user"></i> Dicom Files
    </a>
  </li>
  <li class="nav-item"*ngIf="showcoupon">
    <a class="nav-link" routerLink="/coupons" aria-controls="tab5" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-gift"></i> Coupons
    </a>
  </li>
  <li class="nav-item"*ngIf="showspeciality">
    <a class="nav-link" routerLink="/specialities" aria-controls="tab5" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-user-md"></i>
      Specialities
    </a>
  </li>
  <li class="nav-item" *ngIf="showlanguage">
    <a class="nav-link" routerLink="/languages" aria-controls="tab5" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-language"></i>
      Languages
    </a>
  </li>
  <li class="nav-item" *ngIf="showbank">
    <a class="nav-link" routerLink="/banks" aria-controls="tab5" role="tab" data-toggle="tab" routerLinkActive="active"
      routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-bank"></i>
      Banks
    </a>
  </li>

  <li class="nav-item" *ngIf="showorganizations">
    <a class="nav-link" routerLink="/organizations" aria-controls="tab5" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-hospital-o"></i>
      organizations
    </a>
  </li>
  <li class="nav-item" *ngIf="showProductVideo">
    <a class="nav-link" routerLink="/product-videos" aria-controls="tab5" role="tab" data-toggle="tab"
      routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
      <i class="fa fa-money"></i>
      product-videos
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/reset-password" aria-controls="tab5" role="tab" data-toggle="tab" routerLinkActive="active"
      routerLinkActiveOptions="{exact:true}">
      <i class="fe fe-user"></i> Reset Password
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link cursor-pointer" (click)="logout()">
      <i class="fa fa-sign-out pr-2"></i>Logout
    </a>
  </li>
</ul>