import { LoginService } from "../login/login.service";
import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  reset: any = { password: "", cpassword: "", old_password: "" };
  registerForm: FormGroup;
  submitted = false;
  value: string;
  errMsg: string;
  userId: any;
  msg: any;
  fb: any;
  sub: any;
  is_validate = false;
  passPattern = "^(?=.*?[A-Z])(?=.*?[0-9]).{6,15}$";

  angForm: FormGroup;

  createForm() {
    this.angForm = this.fb.group({
      name: ["", Validators.required],
    });
  }

  constructor(
    private api: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public loginservice: LoginService
  ) {
    // this.value = this.route.snapshot.paramMap.get("code");

    if (this.loginservice.isLoggedIn()){
      this.router.navigateByUrl("/reset-password");
      this.is_validate = true;
    } else {
      this.is_validate = false;
      router.navigateByUrl("/not-found");
        this.errMsg = "";
        return;
    }
    // this.api.validateResetPass(this.value).subscribe(
    //   (jsonData) => {
    //   },
    //   (err) => {
    //     router.navigateByUrl("/not-found");
    //     this.errMsg = "";
    //     return;
    //   }
    // );
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = params;
    });
    this.registerForm = this.formBuilder.group({
      old_password: [
        "",
        [Validators.required],
      ],
      password: [
        "",
        [Validators.required, Validators.pattern(this.passPattern)],
      ],
      cpassword: [
        "",
        [Validators.required, Validators.pattern(this.passPattern)],
      ],
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    console.log('testing2');
    this.submitted = true;
    console.log(this.registerForm.errors);
    if (this.registerForm.invalid) {
      return;
    } else if (this.reset.password != this.reset.cpassword) {
      this.errMsg = "Password mismatch";
      setTimeout(() => {
        this.errMsg = "";
      }, 3000);
      return;
    }
    this.msg = "";
    this.errMsg = "";
    console.log('testing');
    this.api.changePassword(this.reset).subscribe(
      (user) => {
        console.log('testing1');
        if (user) {
          this.msg = "Password change successfully";
          this.openSnackBar("Password reset successfully please login", "");
          this.loginservice.logout();
        }
      },
      (err) => {
        this.errMsg = "Please check details";
        window.scrollTo(0, 0);
      }
    );
  }
  //open toast for success msg
  openSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = "center";
    config.verticalPosition = "top";
    config.panelClass = ["green-snackbar"];
    this.snackBar.open(message, action, config);
  }
}
